import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import constants from '../utils/constants';
import { getLocalImage } from './utils/image-helpers';
import { Helmet } from 'react-helmet';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getClientId, getEnvironment } from '../utils/application';
import { setIsShowingSlide } from '../action-creators/app';
import { fetchClientPromos } from '../action-creators/earn';
import { externalRedirect } from '../utils/url';
import BoxGroup from './BoxGroup';
import { DynamicHero } from './DynamicHero';
import Spinner from './Spinner';
import { EarnRedeemFooter } from './EarnRedeemFooter';
import { AdContainer } from './AdContainer';

export class UnconnectedStart extends React.Component {
  state = {
    windowWidth: window.innerWidth
  };

  componentDidMount() {
    const {
      fetchClientPromos,
      locale,
      partnerLpId
    } = this.props;

    const promoCopyParams = {
      orderType: constants.product.earn,
      orderSubtype: constants.orderSubType.milesEarnedPerDollarSpent,
      locale
    };
    if (partnerLpId) {
      promoCopyParams.lpId = partnerLpId;
    }
    fetchClientPromos(getClientId(), promoCopyParams);
    window.addEventListener('resize', this.setWindowWidth);
  }

  setWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  }

  getHowItWorksContent = () => {
    const { clientData } = this.props;
    const types = clientData.get('landingPageFeatures') || List();
    return types.map(type => {
      const step = {
        icon: `icon-${type}`,
        header: `earn.howItWorks.${type}.header`,
        button: `earn.howItWorks.${type}.button`,
        description: `earn.howItWorks.${type}.description`
      };
      if (type === 'shop') {
        step.onLinkClick = this.showRestrictionsModal;
        step.link = 'earn.howItWorks.shop.link';
      }
      if (type === 'earn-2' || type === 'redeem') {
        step.onButtonClick = this.handleBtnClick;
      }
      return Map(step);
    });
  };

  handleBtnClick = (e, ssoButtonText) => {
    e.preventDefault();
    let ssoFlow = '';
    const { clientData } = this.props;
    const env = getEnvironment();
    const ssoEarnUrl = clientData.getIn(['ssoUrls', env, 'earn']);
    const ssoRedeemUrl = clientData.getIn(['ssoUrls', env, 'redeem']);

    if (ssoButtonText.indexOf('earn-2') > 0) {
      ssoFlow = ssoEarnUrl;
    } else if (ssoButtonText.indexOf('redeem') > 0) {
      ssoFlow = ssoRedeemUrl;
    }
    externalRedirect(ssoFlow);
  }

  renderTempUI = tempUIDates => {
    const start = tempUIDates.get('start');
    const end = tempUIDates.get('end');

    if (!start || !end) return null;

    const now = new Date().getTime();
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();

    if (now >= startTime && now <= endTime) {
      return (
        <div id='earn__tempPromo'>
          <FormattedHTMLMessage id='earn.tempPromo' />
        </div>
      );
    }
    return null;
  }

  renderAd = () => (<AdContainer bannerSrc='banner-background.png' />);

  renderGetStarted = () => {
    const { clientData, partnerLpId } = this.props;
    const joinUrl = clientData.getIn(['joinUrls', partnerLpId]);
    return (
      <div className='earnAndRedeem__getStarted text-center u-padding-small'>
        <div className='earnAndRedeem__getStarted__section section section--compact'>
          <p className='earnAndRedeem__getStarted__text'>
            <FormattedMessage id='earn.start.join.text' />
          </p>
          <a className='earnAndRedeem__getStarted__bottomLink' href={joinUrl} rel='noopener noreferrer' target='_blank'>
            <FormattedMessage id='earn.start.join.statement' />
          </a>
        </div>
      </div>
    );
  }

  renderHero = () => {
    const { clientData } = this.props;
    const partnerDirName = clientData.get('partnerDirName');
    const heroDesktopImage = clientData.get('heroDesktopImage');
    const heroMobileImage = clientData.get('heroMobileImage');
    const ssoEarnUrl = clientData.getIn(['ssoUrls', getEnvironment(), 'earn']);

    const shouldDisplayMobileImage = this.state.windowWidth <= constants.mobileMaxWidth;
    const desktopHeroImage = getLocalImage(partnerDirName, heroDesktopImage);
    const mobileHeroImage = getLocalImage(partnerDirName, heroMobileImage);
    const heroContent = {
      image: (shouldDisplayMobileImage && mobileHeroImage) ? mobileHeroImage : desktopHeroImage,
      header: 'earn.start.hero.header',
      info: 'earn.start.hero.info',
      button: {
        text: 'earn.start.hero.btnText',
        onClick: () => externalRedirect(ssoEarnUrl)
      }
    };

    return (
      <DynamicHero {...heroContent} />
    );
  };

  renderHowItWorks = () => {
    const { isShowingSlide, clientData } = this.props;
    const tempUIDates = clientData.get('tempUIDates');
    return (
      <div className='earn__how-it-works earnAndRedeem__howItWorks section text-center' aria-hidden={isShowingSlide}>
        {tempUIDates && this.renderTempUI(tempUIDates)}
        <h2 className='earn__promo__header earnAndRedeem__howItWorks__header'>
          <FormattedMessage id='earn.howItWorksHeader' />
        </h2>
        <BoxGroup content={this.getHowItWorksContent()} classes='earn__box-group earnAndRedeem__howItWorks__boxGroup' />
      </div>
    );
  };

  render() {
    const { formatter, promoData } = this.props;
    if (promoData?.isEmpty()) {
      return (
        <Spinner />
      );
    }

    return (
      <div className='earn earnAndRedeem'>
        <Helmet>
          <title>{formatter?.formatMessage({ id: 'pageTitle.earn' })}</title>
          <meta
            name='description'
            content={formatter?.formatMessage({ id: 'pageDescription.earn' })}
          />
          <meta
            name='keywords'
            content={formatter?.formatMessage({ id: 'pageKeywords.earn' })}
          />
        </Helmet>
        {this.renderHero()}
        {this.renderHowItWorks()}
        {this.renderAd()}
        {this.renderGetStarted()}
        <EarnRedeemFooter />
      </div>
    );
  }
}

UnconnectedStart.propTypes = {
  clientData: ImmutablePropTypes.map,
  formatter: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  locale: PropTypes.string,
  messages: ImmutablePropTypes.map,
  partnerLpId: PropTypes.string,
  isShowingSlide: PropTypes.bool,
  promoData: ImmutablePropTypes.map,
  fetchClientPromos: PropTypes.func,
  setIsShowingSlide: PropTypes.func
};

const mapStateToProps = state => ({
  clientData: state.app.get('clientData'),
  formatter: state.app.get('formatter'),
  locale: state.app.get('locale'),
  messages: state.app.get('messages'),
  partnerLpId: state.app.get('partnerLpId'),
  isShowingSlide: state.app.get('isShowingSlide'),
  promoData: state.earn.get('promoData')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    fetchClientPromos,
    setIsShowingSlide
  }, dispatch);

export const Start = connect(mapStateToProps, mapDispatchToProps)(UnconnectedStart);
